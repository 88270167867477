<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('Materials')"
      :subtitle="$t('Evaluation')"
      :root="$store.state.courses.course.name"
      :root-location="COURSE_NAVIGATION_RETURN_LINK($store.state.courses.course)"
      icon="mdi-text-box-check-outline"
      :side-navigation="EVALUATION_NAVIGATION"
      navigation-route="evaluation"
      fillHeight
      @resetFilter="$store.state.settings.filtersData = {}"
      toggle

      :no-content-condition="entity.modules.length === 0"
      :no-content-button-route="businessDashLink('courses/' + uuid + '/editor')"
      :no-content-button-text="MODULE_PERMISSIONS(['MODULE_WESTUDY__EDITOR'], true) ? 'CourseEditor' : null"
      no-content-text="NoTasksMessage"
      no-content-icon="mdi-text-box-check-outline"
      :loading="loading"
  >

    <template #default>

      <div class="mt-6" >
        <v-sheet v-for="(module,i) in entity.modules" :key="i + 'semester'" :ref="'module_' + i" class="wsRounded ">
          <!-- Module-->
          <v-sheet class="wsRoundedLightTopSemi py-2 px-4" dark :color="wsACCENT">
            <h4> {{ module.name }}</h4>
          </v-sheet>
          <v-sheet :style="`border : 1px solid ${wsBACKGROUND}`" class="wsRoundedLightBottom mb-5">
            <template  v-for="(topic,j) in module.topics">
              <h5 class="px-4 py-2" :key="'topic.name' + i + j">
                {{ topic.name }}
              </h5>

              <template v-for="(component, k) in topic.components">
                <v-hover
                    v-if="$store.state.evaluation.displayAllComponentsEvaluation || component.is_task"
                    v-slot="{hover}"
                    :key="'component' + i + j + k "
                >
                  <router-link
                      :to="businessDashLink('courses/' + uuid + '/evaluation/components/' + component.uuid)"
                      :is="!component.is_task ? 'span' : 'router-link'"
                      :class="component.is_task ? 'pointer' : null"
                      class="noUnderline"

                      style="color: black "
                  >
                    <v-sheet :color="hover ? wsLIGHTCARD : null">

                      <v-divider  :style="`border-color : ${wsBACKGROUND}`" />

                      <div class="d-flex align-center justify-space-between px-4 py-2">
                        <div class="d-flex align-center" >
                          <v-icon class="mr-3"  :color="getEntityTypeIconColor(component)" > {{ getEntityTypeIcon(component.entity_type ) }}</v-icon>
                          <h5  :style="`color : ${wsACCENT}`"
                               :class="component.is_task ? 'hoverUnderline' : null"
                               v-html="component.name" />
                        </div>

                        <div v-if="component.entity_type === 'task_test'" class="d-flex align-center">
                          <h5 v-if="component.test_tries > 0 && !SM"
                              :style="`color : ${wsDARKLIGHT}`"
                              class="mr-3">
                            <v-icon :color="wsDARKLIGHT" >mdi-flag</v-icon>
                            {{ $t('TestTries') }}: {{ component.test_tries }}
                          </h5>
                        </div>

                        <div v-else-if="!component.is_task" class="d-flex align-center">
                          <h5 v-if="component.views > 0 && !SM"
                              :style="`color : ${wsDARKLIGHT}`"
                              class="mr-3">
                            <v-icon :color="wsDARKLIGHT" >mdi-eye</v-icon>
                            {{ $t('Views') }}: {{ component.views }}
                          </h5>
                        </div>



                        <div v-else class="d-flex align-center">
                          <h5 v-if="component.replies >0 && !SM"
                              :style="`color : ${wsATTENTION}`"
                              class="mr-3">
                            <v-icon :color="wsATTENTION" >mdi-forum</v-icon>
                            {{ $t('Answers') }}: {{ component.replies }}
                          </h5>
                        </div>

                      </div>

                    </v-sheet>
                  </router-link>
                </v-hover>

              </template>

            </template>



          </v-sheet>
        </v-sheet>
      </div>
    </template>
  </sub-page>


</template>

<script>
import {mapActions, mapMutations} from "vuex";

export default {
  name: "evaluationComponents",
  props : {
    uuid : {
      type : String,
      default : ''
    }
  },
  data() {
    return {
      displayTasksOnly : false,
      loading : true,
      entity : {
        modules : []
      }
    }
  },
  methods : {
    ...mapActions('evaluation',[ 'GET_COURSE_EVALUATION_COMPONENTS', ]),
    ...mapMutations('courses' , ['INIT_PAGE']),
    getEntityTypeIcon( type ) {

      let icon = ''
      switch ( type ) {
        case 'lecture'    :  icon = 'mdi-book-open'       ; break;
        case 'task'       :  icon = 'mdi-content-paste'                ; break;
        case 'task_video' :  icon = 'mdi-cast-education'               ; break;
        case 'test'       :  icon = 'mdi-order-bool-ascending-variant' ; break;
        case 'task_test'  :  icon = 'mdi-order-bool-ascending-variant' ; break;
        case 'video'      :  icon = 'mdi-video'                        ; break;
        case 'file'       :  icon = 'mdi-file'                         ; break;
        default : icon = 'mdi-bookmark'
      }
      return icon

    },
    getEntityTypeIconColor( component ) {
      return component.views > 0 || component.replies > 0 ? this.wsATTENTION : 'grey'
    },

  },
  async mounted() {
    let result = await this.GET_COURSE_EVALUATION_COMPONENTS(this.uuid)
    if ( !result ) {
      this.loading = false
      return
    }
    result.modules.forEach(module => {
      module.topics = module.topics.filter( el=>el.components.length > 0 )
    })
    result.modules = result.modules.filter( el => el.topics.length > 0)
    this.entity = result
    this.loading = false
  }
}
</script>

<style scoped>
.hoverUnderline:hover {
  color : #567186 !important;
  text-decoration: underline;
}
</style>